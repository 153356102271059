import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import AnalyticsSidebar from "./AnalyticsSidebar";
import { getPowerBIReportAccessDetails } from "../User/UserFunctions";
import "./Analytics.css"

const stages = [
  "Initializing analytics engine...",
  "Connecting to data sources...",
  "Processing visualization data...",
  "Preparing interactive dashboard...",
  "Optimizing display components..."
];

function LoadingStages({ currentStage, isFinished }) {
  return (
    <div className={`loading-stages${isFinished ? ' finished' : ''}`}>
      {isFinished ? "Finished loading" : stages[currentStage]}
    </div>
  );
}

function Analytics() {
  const [loading, setLoading] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [currentAnalyticID, setCurrentAnalyticID] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [reportRendered, setReportRendered] = useState(false);
  const [currentStage, setCurrentStage] = useState(0);
  const [showFinished, setShowFinished] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const MAX_RETRIES = 3;

  useEffect(() => {
    const interval = setInterval(() => {
      if (!showFinished) {
        setCurrentStage((prev) => (prev + 1) % stages.length);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [showFinished]);

  const handleReportRendered = useCallback(() => {
    setShowFinished(true);
    setTimeout(() => {
      setFadeOut(true);
      setTimeout(() => {
        setReportRendered(true);
      }, 500); // Match CSS transition duration
    }, 1000); // Show "Finished" message for 1 second
  }, []);

  const refreshToken = useCallback(async (isAutoRetry = false) => {
    if (!currentAnalyticID) return;
    
    if (isAutoRetry && retryCount >= MAX_RETRIES) {
      console.error("Max retry attempts reached");
      return;
    }

    setLoading(true);
    setReportRendered(false);
    setShowFinished(false);
    setFadeOut(false);
    try {
      const details = await getPowerBIReportAccessDetails(parseInt(currentAnalyticID, 10));
      console.log("Refreshed report details received:", details);
      setReportDetails(details);
      setRetryCount(0); // Reset retry count on success
    } catch (error) {
      console.error("Error refreshing token:", error);
      // Check if error is due to authentication (401)
      if (error?.errors?.[0]?.message?.includes("401")) {
        console.log("Authentication error detected, retrying...");
        setRetryCount(prev => prev + 1);
        // Add a small delay before retry
        setTimeout(() => refreshToken(true), 2000);
      }
    } finally {
      setLoading(false);
    }
  }, [currentAnalyticID, retryCount]);

  // Set up an interval to refresh the token periodically
  useEffect(() => {
    if (!currentAnalyticID) return;
    
    // Refresh token every 45 minutes to prevent expiration
    const interval = setInterval(() => {
      console.log("Performing scheduled token refresh");
      refreshToken();
    }, 45 * 60 * 1000); // 45 minutes in milliseconds

    return () => clearInterval(interval);
  }, [currentAnalyticID, refreshToken]);

  const handleSelectedAnalyticID = async (selectedAnalyticID) => {
    setLoading(true);
    setReportRendered(false);
    setShowFinished(false);
    setFadeOut(false);
    setCurrentAnalyticID(selectedAnalyticID);
    console.log("Fetching report details for analytic id:", selectedAnalyticID);
    try {
      const details = await getPowerBIReportAccessDetails(parseInt(selectedAnalyticID, 10));
      console.log("Report details received:", details);
      setReportDetails(details);
    } catch (error) {
      console.error("Error fetching report details:", error);
    }
    setLoading(false);
  };
  
  const renderSplashScreen = () => (
    <div className={`splash-screen${fadeOut ? ' fade-out' : ''}`}>
      <div className={`loading-circle${showFinished ? ' finished' : ''}`}>
        <CircularProgress size={40} thickness={4} />
      </div>
      <LoadingStages currentStage={currentStage} isFinished={showFinished} />
    </div>
  );

  return (
    <Box className="analyticsBody" sx={(theme) => ({ background: theme.palette.ke.background })}>
      <AnalyticsSidebar onSelectAnalyticID={handleSelectedAnalyticID} />
      <div style={{ position: 'relative', flex: 1, height: '100%' }}>
        {loading ? (
          renderSplashScreen()
        ) : reportDetails ? (
          <>
            <PowerBIEmbed
              cssClassName={"powerbi-container"}
              embedConfig={{
                type: "report",
                embedUrl: reportDetails.report_url,
                tokenType: models.TokenType.Embed,
                accessToken: reportDetails.embed_token,
                settings: {
                  filterPaneEnabled: reportDetails.filter_pane_enabled,
                  navContentPaneEnabled: reportDetails.nav_content_pane_enabled,
                  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  ["loaded", function () { console.log("Report loaded"); }],
                  ["rendered", function () { 
                    console.log("Report rendered");
                    handleReportRendered();
                  }],
                  ["error", function (event) { 
                    console.error("PowerBI Error:", event.detail); 
                    // Check if the error is a token expiration
                    const errors = event.detail;
                    if (Array.isArray(errors) && errors.some(error => 
                      error.message === "TokenExpired" || 
                      error.detailedMessage?.includes("TokenExpired") ||
                      error.message?.includes("401") ||
                      error.detailedMessage?.includes("401")
                    )) {
                      console.log("Token expired or auth error, refreshing...");
                      refreshToken(true);
                    }
                  }],
                  ["dataSelected", function(event) { console.log("Data selected event:", event.detail); }],
                ])
              }
              getEmbeddedComponent={(embeddedReport) => {}}
            />
            {!reportRendered && renderSplashScreen()}
          </>
        ) : null}
      </div>
    </Box>
  );
}

export default Analytics;
