import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import LogRocket from 'logrocket';

// Session logging

const logRocketProject = process.env.REACT_APP_LOGROCKET_PROJECT || 'au3xfa/alpha_pilot_dev_local'; // Fallback to 'dev' if not set
LogRocket.init(logRocketProject);

// Overwrite aws_appsync_graphqlEndpoint using environment variable with fallback
const appsyncGraphqlEndpoint = process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || "https://xz3iefuitvartcb3umndrnau4u.appsync-api.us-west-2.amazonaws.com/graphql"

Amplify.configure({
  ...awsExports,  // Keep other existing configuration
  aws_appsync_graphqlEndpoint: appsyncGraphqlEndpoint,  // Overwrite the GraphQL endpoint
});

const apiEndpoint = process.env.REACT_APP_ALPHA_PILOT_LOG_API_ENDPOINT || 'https://client-log-dev.api.kessler.energy'; // Fallback to 'dev' if not set

Amplify.configure({
  aws_cloud_logic_custom: [
    {
      "name": "clientLoggingAPI",
      "endpoint": apiEndpoint,
      "region": "us-west-2"
  }
  ]
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
